/** @jsx jsx */
import { css, jsx } from '@emotion/core'

// Components
import { Link } from 'gatsby'

const Link3 = ({children, to, color='black', hoverColor='black'}) => (
  <Link
    css={css`
      color: ${color};
      font-size: 1.5em;
      font-family: 'Roboto';
      font-weight: 300;
      text-decoration: none;
      transition: 0.2s;
      &:hover {
        color: ${hoverColor}
      }
    `}
    to={to}
  >
    {children}
  </Link>
)

export default Link3