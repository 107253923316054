/** @jsx jsx */
import { css, jsx } from '@emotion/core'

// Components
import { Link } from 'gatsby'

const Link2 = ({text, to}) => (
  <Link
    css={css`
      display: block;
      padding: 10px 20px;
      background-color: #FFFFFF;
      color: #70926E;
      border: 1px solid #70926E;
      border-radius: 5px;
      text-align: center;
      font-size: 1.25em;
      font-family: 'Quicksand';
      font-weight: bold;
      text-decoration: none;
      transition: 0.1s;
      &:hover {
        background-color: #70926E;
        border-color: #FFFFFF;
        color: #FFFFFF;
      }
    `}
    to={to}
  >
    {text}
  </Link>
)

export default Link2