/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { StaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

const ImgLinkCamillaHall = () => (
  <StaticQuery
    query={graphql`
      query {
        placeholderImage: file(relativePath: { eq: "portfolio/camilla-hall/catharine-ann-farnen-landscape-architecture-portfolio-camilla-hall-9-pennsylvania.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 250) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={data => <Img css={css`height: 100%; width: 100%;`} alt='camilla hall catharine ann farnen landscape architecture' fluid={data.placeholderImage.childImageSharp.fluid} />}
  />
)
export default ImgLinkCamillaHall
