/** @jsx jsx */
import {jsx, css} from '@emotion/core'

const Subtitle2 = ({children, color='#FF6B6B', fontFamily='Quicksand', fontSize='1.25em'}) => (
  <h5 css={css`
    color: ${color};
    font-family: ${fontFamily};
    font-size: ${fontSize};
    margin: 0;
  `}>{children}</h5>
)

export default Subtitle2