import React from 'react'
import styled from '@emotion/styled'

// Components
import Header1 from '../atoms/header-1'
import Paragraph1 from '../atoms/paragraph-1'

// Images
import ImgFooterForm from '../images/footer/img-footer-form'
import Form1 from '../atoms/form-1';
import CurveWhite from '../atoms/curve-white';

// Styles
const maxWidth = '800px';
const Container = styled.section`
  z-index: 1;
  position: relative;
  margin-top: -150px;
  padding: 300px 15px;
  text-align: center;
  overflow: hidden;
`
const BackgroundWhiteCurve = styled.div`
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 170px;
  pointer-events: none;
`
const BackgroundImage = styled.div`
  z-index: -1;
  position: absolute;
  top: 20px;
  left: 0;
  min-width: 1920px;
  height: calc(100% - 20px);
  pointer-events: none;
  img {
    object-position: top!important;
  }
`
const ContainerText = styled.section`
  max-width: ${maxWidth};
  margin: 0 auto 30px auto;
`
const ContainerForm = styled.section`
  max-width: ${maxWidth};
  margin: 0 auto;
`

const FooterConnect = ({ children }) => (
  <Container>
    <BackgroundWhiteCurve>
      <CurveWhite fill1='transparent'/>
    </BackgroundWhiteCurve>
    <BackgroundImage>
      <ImgFooterForm/>
    </BackgroundImage>

    <ContainerText>
      <Header1 color='#ffffff'>Connect With Catharine</Header1>
      <Paragraph1 color='#ffffff'>Need a professional architect for your landscape? Have questions about a project?</Paragraph1>
      <Paragraph1 color='#ffffff'>Send me a message using the form below to get started!</Paragraph1>
    </ContainerText>
    <ContainerForm>
      <Form1/>
    </ContainerForm>
  </Container>
)

export default FooterConnect