/** @jsx jsx */
import { css, jsx } from '@emotion/core'

const CurveWhiteGreen = () => (
  <svg css={css`position: absolute; bottom: 0;`} width="1920" height="405" viewBox="0 0 1920 405" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M928.271 83.7548C1553.84 -63.375 1850.9 27.8181 2099 29.1704V62.4283C1850.9 61.076 1553.84 -30.1171 928.271 117.013C302.702 264.143 68.5098 175.285 -179 176.576V143.318C68.5098 142.027 302.702 230.885 928.271 83.7548Z" fill="#70926E"/>
    <path d="M928.271 140.664C1553.84 -6.30634 1850.9 84.7879 2099 86.1388V426H-179V200.163C68.5099 198.873 302.702 287.634 928.271 140.664Z" fill="#70926E"/>
  </svg>
)

export default CurveWhiteGreen