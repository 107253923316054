import React from 'react'
import MediaQuery from 'react-responsive';
/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import styled from '@emotion/styled'

// Components
import { Link } from 'gatsby'
import Link1 from '../atoms/link-1'
import Link2 from '../atoms/link-2'
import Link5 from '../atoms/link-5'
import Link6 from '../atoms/link-6'
import Subtitle2 from '../atoms/subtitle-2'
import { slide as Menu } from 'react-burger-menu'

// Images
import ImgLogo from '../images/logo/img-logo'
import ImgMobileButton from '../../images/_icons/mobile-menu-button.svg'

// Desktop Styles
const LinkList = styled.ul`
  padding: 0;
  margin: 0;

  position: relative;
  display: flex;
  align-items: center;
`
const LinkItem = styled.li`
  list-style: none;

  position: relative;
  margin-left: 60px;
  z-index: 10;
  > a {
    position: relative;
    z-index: 10;
  }
`
const LinkSeperator = styled.div`
  height: 30px;
  width: 2px;
  background: white;
`
const SubMenu = styled.section`
  position: absolute;
  display: flex;
  flex-wrap: nowrap;
  width: 375px;
  background: #ffffff;
  padding: 5px 5px 5px 5px;
  overflow: hidden;
  top: 34px;

  opacity: 0;
  transform: translateX(-15px);
  transition: 0.3s;
  pointer-events: none;
  @media (min-width: 1700px) {
    top: 35px;
  }
`
const SubList = styled.ul`
  padding: 5px;
  margin: 0;

  background: none;
  width: 175px;
  text-align: center;
`
const SubListItem = styled.li`
  list-style: none;
  
  margin: 30px 0;
  &:first-of-type {
    margin-top: 15px;
  }
  &:last-child {
    margin-bottom: 15px;
  }
`
const SubListContainerImage = styled.section`
  width: 175px;
  height: auto;
  max-height: 163px;
  padding-left: 15px;
  text-align: center;
`
const SubListTypeContainer = styled.div`
  margin-top: 15px;
`

// Mobile Styles
const mobileStyles = {
  bmBurgerButton: {
    position: 'fixed',
    padding: '20px',
    width: '47px',
    height: '47px',
    right: '0px',
    top: '0px',
    background: 'rgba(82, 117, 92, 0.82)',
    mixBlendMode: 'normal',
  },
  bmBurgerBars: {
    background: '#FFFFFF'
  },
  bmBurgerBarsHover: {
    opacity: '0.2'
  },
  bmCrossButton: {
    height: '24px',
    width: '24px',
    left: '15px',
  },
  bmCross: {
    background: '#bdc3c7'
  },
  bmItemList: {
    display: 'block',
  },
  bmMenuWrap: {
    top: '0',
    background: 'rgba(0,0,0,0.85)',
  },
  bmMenu: {
    overflowX: 'hidden',
  },
  bmOverlay: {
    top: '0',
    left: '0'
  },
}
const MobileLinkList = styled.ul`
  display: flex;
  flex-direction: column;
  list-style: none;
  padding: 30px 0;
  margin: 0;
  width: 250px;
  margin: 0 auto;
  outline: none;
`
const MobileLinkItem = styled.li`
  width: 100%;
  padding: 5px 15px;
  box-sizing: border-box;
`
const MobileSubLinkItem = styled.li`
  width: 100%;
  padding: 5px 15px 5px 30px;
  margin: 7.5px 0;
`
const MobileLinkSeperator = styled.div`
  height: 2px;
  width: 100%;
  margin: 30px 0;
  background: white;
`

class MainNav extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      hovered: -1,
      hoveredImage: 0,
    }

    this.hovered = {
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
    }

    this.handleLinkHover = this.handleLinkHover.bind(this)
    this.handleSubLinkHover = this.handleSubLinkHover.bind(this)
  }

  handleLinkHover(event) {
    if(typeof window !== 'undefined' && 
      typeof document !== 'undefined' && 
      event.currentTarget.dataset.linkId !== this.state.hovered &&
      document.querySelector(`section[data-sub-menu-id='${event.currentTarget.dataset.linkId}']`)
    ) {
      const subMenu = document.querySelector(`section[data-sub-menu-id='${event.currentTarget.dataset.linkId}']`).getBoundingClientRect()
      this.hovered.left = subMenu.left
      this.hovered.right = subMenu.right
      this.hovered.top = subMenu.top
      this.hovered.bottom = subMenu.bottom

      const mainThis = this

      window.addEventListener('mousemove', function _mousemove(event) {
        if( event.clientX < mainThis.hovered.left || 
          event.clientX > mainThis.hovered.right || 
          event.clientY < mainThis.hovered.top - 50 || 
          event.clientY > mainThis.hovered.bottom + 30
          ) {
            window.removeEventListener('mousemove', _mousemove, false)
            mainThis.setState({hovered: -1, hoveredImage: 0})
        }
      })
    }
    
    this.setState({hovered: event.currentTarget.dataset.linkId})
  }

  handleSubLinkHover(event) {
    this.setState({hoveredImage: event.currentTarget.dataset.linkId})
  }

  render() {
    if(typeof document !== 'undefined') {
      document.querySelectorAll(`section[data-sub-menu-id]`).forEach(item => {
        if(this.state.hovered === item.dataset.subMenuId) {
          item.style.transform = 'translateY(20px) translateX(-15px)'
          item.style.opacity = 1
          item.style.zIndex = 1
          item.style.pointerEvents = 'initial'
        }
        else {
          item.style.transform = 'translateY(0) translateX(-15px)'
          item.style.opacity = 0
          item.style.zIndex = -1
          item.style.pointerEvents = 'none'
        }
      })
    }

    return(
      <>
   
        {/* Mobile Navigation */}
        
        <MediaQuery maxWidth={991}>

          <Menu styles={mobileStyles} right customBurgerIcon={<img src={ImgMobileButton} alt='mobile menu button'/>}>
            <MobileLinkList>
              <MobileLinkItem css={css`margin-bottom: 15px;`}>
                <Link to='/'>
                  <ImgLogo/>
                </Link>
              </MobileLinkItem>
              {this.props.navigation.map((item, i) => (
                <div key={i}>
                  <MobileLinkItem>
                    <Link1 to={item.linkURL} text={item.linkTitle} hoverColor={'none'} linkid={i} />
                  </MobileLinkItem>
                  {item.subLinks && item.subLinks.map((subItem, j) => (
                    <div key={j}>
                      <MobileSubLinkItem key={j}>
                        <Link1 to={subItem.linkURL} linkid={j} text={subItem.linkTitle} color={'#CCCCCC'} fontFamily='Roboto' hoverColor={'#70926E'} />
                      </MobileSubLinkItem>
                      <SubListContainerImage>
                        {this.state.hovered < 1 ? null : this.props.navigation[this.state.hovered].subLinks[this.state.hoveredImage].linkImage}
                      </SubListContainerImage>
                    </div>
                  ))}
                </div>
              ))}

              {/* Contact Info */}

              <MobileLinkItem>
                <MobileLinkSeperator/>
              </MobileLinkItem>
              <MobileLinkItem>
                <Link6 href='tel:(267) 304 1674' fontSize='1.25em'>(267) 304 1674</Link6>
              </MobileLinkItem>
              <MobileLinkItem>
                <Link2 to='/contact' text='Connect'/>
              </MobileLinkItem>
            </MobileLinkList>
          </Menu>

        </MediaQuery>

        {/* Desktop Menu */}

        <MediaQuery minWidth={992}>

          <LinkList>
            {this.props.navigation.map((item, i) => (
              <LinkItem key={i}>
                <Link1 to={item.linkURL} text={item.linkTitle} linkid={i} onMouseOver={this.handleLinkHover}/>
                {item.subLinks &&
                  <SubMenu data-sub-menu-id={i}>
                    <SubList>
                    {item.subLinks.map((subItem, j) => (
                      <SubListItem key={j}>
                        <Link1 to={subItem.linkURL} linkid={j} text={subItem.linkTitle} color={'#2B4829'} fontFamily='Roboto' hoverColor={'#70926E'} onMouseOver={this.handleSubLinkHover}/>
                      </SubListItem>
                    ))}
                    </SubList>
                    <SubListContainerImage>
                      {this.state.hovered < 1 ? null : this.props.navigation[this.state.hovered].subLinks[this.state.hoveredImage].linkImage}
                      {this.state.hovered > 0 && 
                        <SubListTypeContainer>
                          <Subtitle2 fontFamily='Poiret One' color='#70926E' fontSize='1.5em'>{this.props.navigation[this.state.hovered].subLinks[this.state.hoveredImage].linkType}</Subtitle2>
                        </SubListTypeContainer>
                      }
                    </SubListContainerImage>
                  </SubMenu>
                }
              </LinkItem>
            ))}
            
            {/* Contact Information */}

            <LinkItem>
              <LinkSeperator/>
            </LinkItem>
            <LinkItem>
              <Link5 href='tel:(267) 304 1674' fontSize='1.25em'>(267) 304 1674</Link5>
            </LinkItem>
            <LinkItem>
              <Link2 to='/contact' text='Contact'/>
            </LinkItem>
          </LinkList>

        </MediaQuery>
      </>
    )
  }
} 

export default MainNav