import React from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'
import { Global, css } from '@emotion/core'

// Components
import Navbar from './organisms/navbar'
import FooterConnect from './organisms/footer-connect'
import FooterInfo from './organisms/footer-info'

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <>
        <Global styles={css`
          body {
            position: relative;
            margin: 0;
            font-size: 75%;
            @media (min-width: 768px) {
              font-size: 75%;
            }
            @media (min-width: 992px) {
              font-size: 78%;
            }
            @media (min-width: 1200px) {
              font-size: 80%;
            }
            @media (min-width: 1700px) {
              font-size: 90%;
            }
          }
        `}/>
        <Navbar/>
          {children}
        <FooterConnect/>
        <FooterInfo/>
      </>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout