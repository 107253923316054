/** @jsx jsx */
import { css, jsx } from '@emotion/core'

// Components
import { Link } from 'gatsby'

const Link1 = ({
  text, 
  to, 
  color='#FFFFFF', 
  fontFamily='Quicksand', 
  hoverColor='#CCCCCC', 
  fontSize='1.25em', 
  linkid, 
  onMouseOver
}) => {

  // Check if the link is an internal link
  const internal = /^\/(?!\/)/.test(to)

  if(internal) {
    return(
      <Link
        data-link-id={linkid}
        onMouseOver={onMouseOver}
        css={css`
          color: ${color};
          font-family: ${fontFamily};
          font-size: ${fontSize};
          font-weight: 300;
          text-decoration: none;
          transition: 0.1s;
          &:hover {
            color: ${hoverColor}
          }
        `}
        to={to}
      >
        {text}
      </Link>
    )
  }
  else {
    return(
      <a
        data-link-id={linkid}
        onMouseOver={onMouseOver}
        css={css`
          color: ${color};
          font-family: ${fontFamily};
          font-size: ${fontSize};
          font-weight: 300;
          text-decoration: none;
          transition: 0.1s;
          &:hover {
            color: ${hoverColor}
          }
        `}
        href={to}
      >
        {text}
      </a>
    )
  }
}

export default Link1