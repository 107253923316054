/** @jsx jsx*/
import { css, jsx } from '@emotion/core'

const ImgIconSubmitArrow = ({ to }) => (
  <svg css={css`
    display: inline-block;
    margin-left: 10px;
    path {
      transition: 0.1s;
      fill: #70926E;
    }
  `} 
    width="20" 
    height="22" 
    viewBox="0 0 26 33" 
    fill="none" 
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M26 16.5L0.499998 32.5215L0.5 0.47853L26 16.5Z" fill="#70926E"/>
  </svg>
)

export default ImgIconSubmitArrow