
/** @jsx jsx */
import {css, jsx} from '@emotion/core'
import styled from '@emotion/styled'

// Components
import { Link } from 'gatsby'
import MainNav from '../molecules/main-nav'

// Images
import ImgLogo from '../images/logo/img-logo'
import ImgLinkJacksonvilleGreen from '../images/links/portfolio/img-link-jacksonville-green';
import ImgLink58thStreetCampus from '../images/links/portfolio/img-link-58th-street-campus';
import ImgLinkCamillaHall from '../images/links/portfolio/img-link-camilla-hall';
import ImgLinkFallsRiverRetreat from '../images/links/portfolio/img-link-falls-river-retreat';
import ImgLinkManningtonMills from '../images/links/portfolio/img-link-mannington-mills';
import ImgLinkOutdoorDining from '../images/links/portfolio/img-link-outdoor-dining';
import ImgLinkPipersvilleMeadow from '../images/links/portfolio/img-link-pipersville-meadow';
import ImgLinkRosemont from '../images/links/portfolio/img-link-rosemont';
import ImgLinkSomerfordPlace from '../images/links/portfolio/img-link-somerford-place';
import ImgLinkTannerPark from '../images/links/portfolio/img-link-tanner-park';
import ImgLinkTheOldMill from '../images/links/portfolio/img-link-the-old-mill';
import ImgLinkDockwoods from '../images/links/portfolio/img-link-dockwoods'

// Styles
const Container = styled.section`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 30px;
  background: rgba(82, 117, 92, 0.82);
  mix-blend-mode: normal;
  transition: 0.2s;
`

// Data
const dataNavigation = [
  {
    linkTitle: 'Landscape Architecture',
    linkURL: '/landscape-architecture',
  },
  {
    linkTitle: 'Portfolio',
    linkURL: '/portfolio/jacksonville-green',
    subLinks: [
      {
        linkTitle: 'Dockwoods',
        linkType: 'In Progress',
        linkURL: '/portfolio/dockwoods',
        linkImage: <ImgLinkDockwoods/>
      },
      {
        linkTitle: 'Jacksonville Green',
        linkType: 'In Progress',
        linkURL: '/portfolio/jacksonville-green',
        linkImage: <ImgLinkJacksonvilleGreen/>
      },
      {
        linkTitle: '58th Street Campus',
        linkType: 'Institution',
        linkURL: '/portfolio/58th-street-campus',
        linkImage: <ImgLink58thStreetCampus/>
      },
      {
        linkTitle: 'Camilla Hall',
        linkType: 'Institution',
        linkURL: '/portfolio/camilla-hall',
        linkImage: <ImgLinkCamillaHall/>
      },
      {
        linkTitle: 'Falls River Retreat',
        linkType: 'Residence',
        linkURL: '/portfolio/falls-river-retreat',
        linkImage: <ImgLinkFallsRiverRetreat/>
      },
      {
        linkTitle: 'Mannington Mills',
        linkType: 'Business',
        linkURL: '/portfolio/mannington-mills',
        linkImage: <ImgLinkManningtonMills/>
      },
      {
        linkTitle: 'Outdoor Dining',
        linkType: 'Residence',
        linkURL: '/portfolio/outdoor-dining',
        linkImage: <ImgLinkOutdoorDining/>
      },
      {
        linkTitle: 'Pipersville Meadow',
        linkType: 'Residence',
        linkURL: '/portfolio/pipersville-meadow',
        linkImage: <ImgLinkPipersvilleMeadow/>
      },
      {
        linkTitle: 'Rosemont',
        linkType: 'Institution',
        linkURL: '/portfolio/rosemont',
        linkImage: <ImgLinkRosemont/>
      },
      {
        linkTitle: 'Somerford Place',
        linkType: 'Institution',
        linkURL: '/portfolio/somerford-place',
        linkImage: <ImgLinkSomerfordPlace/>
      },
      {
        linkTitle: 'Tanner Park',
        linkType: 'Community',
        linkURL: '/portfolio/tanner-park',
        linkImage: <ImgLinkTannerPark/>
      },
      {
        linkTitle: 'The Old Mill',
        linkType: 'Business',
        linkURL: '/portfolio/the-old-mill',
        linkImage: <ImgLinkTheOldMill/>
      },
    ]
  },
]

const Navbar = () => (
  <Container id='main-nav'>
    <Link to='/' css={css`
      display: block; 
      width: 175px;
      transition: 0.2s;
      &:hover {
        opacity: 0.8;
      }
    `}>
      <ImgLogo/>
    </Link>
    <MainNav navigation={dataNavigation}/>
  </Container>
)

export default Navbar