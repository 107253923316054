/** @jsx jsx */
import { css, jsx } from '@emotion/core'

const CurveWhite = ({fill1='#ffffff', fill2='#ffffff', fill2Opacity=1}) => (
  <svg width="1920" height="171" viewBox="0 0 1920 171" fill="none" xmlns="http://www.w3.org/2000/svg" css={css`position: absolute; bottom: -1px; left: 0;`}>
    <path d="M933.257 97.5139C1460.51 -26.3554 1710.89 50.4205 1920 51.559V170H0V147.66C208.612 146.574 406 221.383 933.257 97.5139Z" fill={fill1}/>
    <path d="M933.257 70.5135C1460.51 -53.3558 1710.89 23.4201 1920 24.5586V52.5586C1710.89 51.4201 1460.51 -25.3558 933.257 98.5135C406 222.383 208.612 147.573 0 148.66V120.66C208.612 119.573 406 194.383 933.257 70.5135Z" fill={fill2} fillOpacity={fill2Opacity}/>
  </svg>
)

export default CurveWhite