import React from 'react'
import styled from '@emotion/styled'

// Components
import CurveWhiteGreen from '../atoms/curve-white-green'
import FooterSitemap from '../molecules/footer-sitemap'
// import Social from '../molecules/social'
import Copyright from '../atoms/copyright'

// Images
import ImgLogo from '../images/logo/img-logo'

// Styles
const Container = styled.section`
  position: relative;
  width: 100%;
  padding-bottom: 30px;
  background: #ffffff;
  @media (max-width: 768px) {
    padding-bottom: 15px;
  }
`
const Background = styled.div`
  position: absolute;
  width: 100%;
  height: 420px;
  bottom: 0;
  right: 0;
  overflow: hidden;
`
const ContainerLogo = styled.section`
  margin: 0 auto;
  margin-top: 200px;
  max-width: 300px;
  @media (max-width: 1280px) {
    margin-top: 175px;
    max-width: 250px;
  }
  @media (max-width: 768px) {
    margin-top: 150px;
    max-width: 225px;
  }
`
const ContainerCopyright = styled.section`
  text-align: center;
  margin-top: 30px;
`

const FooterInfo = () => (
  <Container>
    <Background>
      <CurveWhiteGreen/>
    </Background>
    <FooterSitemap/>
    {/* <Social/> */}
    <ContainerLogo>
      <ImgLogo/>
    </ContainerLogo>
    <ContainerCopyright>
      <Copyright/>
    </ContainerCopyright>
  </Container>
)

export default FooterInfo