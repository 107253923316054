import React from 'react'
import styled from '@emotion/styled'

// Styles
const H4 = styled.h4`
  color: #70926E;
  font-family: 'Roboto';
  font-weight: 'bold';
  font-size: 1.5em;
  margin-top: 0;
`

const Header4 = ({children}) => (
  <H4>{children}</H4>
)

export default Header4