import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

const ImgLogo = () => (
  <StaticQuery
    query={graphql`
      query {
        placeholderImage: file(relativePath: { eq: "_logo/catharine-ann-farnen-landscape-architecture-logo-white-pennsylvania.png" }) {
          childImageSharp {
            fluid(maxWidth: 300) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={data => {
      return(
        <Img alt='catharine ann farnen, llc landscape architecture ' fluid={data.placeholderImage.childImageSharp.fluid} critical />
      )
    }
  }
  />
)

export default ImgLogo