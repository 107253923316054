import React from 'react'
import styled from '@emotion/styled'

// Components
import Header4 from '../atoms/header-4'
import Link3 from '../atoms/link-3'
import Link5 from '../atoms/link-5'

// Styles
const Container = styled.section`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  max-width: 80%; 
  padding: 100px 100px 50px 100px;
  margin: 0 auto;
  @media (max-width: 1024px) {
    max-width: initial;
    padding: 100px 30px 50px 30px;
  }
`
const List = styled.ul`
  padding: 0;
  margin: 0;
  list-style: none;
  @media (max-width: 768px) {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    width: 50%;
    margin-bottom: 60px;
  }
  @media (max-width: 550px) {
    width: 100%;
    margin-bottom: 30px;
    justify-content: center;
  }
`
const ListItem = styled.li`
  margin-bottom: 15px;
  @media (max-width: 768px) {
    width: 80%;
  }
  @media (max-width: 550px) {
    text-align: center;
    width: 100%;
  }
`

const FooterSitemap = () => (
  <Container>
    <List>
      <ListItem><Header4>Landscape Architecture</Header4></ListItem>
      <ListItem><Link3 to='/landscape-architecture' hoverColor={'#70926E'}>Design Process</Link3></ListItem>
      <ListItem><Link3 to='/landscape-architecture' hoverColor={'#70926E'}>Services</Link3></ListItem>
    </List>
    <List>
      <ListItem><Header4>Portfolio</Header4></ListItem>
      <ListItem><Link3 to='/portfolio/jacksonville-green' hoverColor={'#70926E'}>Jacksonville Green</Link3></ListItem>
      <ListItem><Link3 to='/portfolio/58th-street-campus' hoverColor={'#70926E'}>58th Street Campus</Link3></ListItem>
      <ListItem><Link3 to='/portfolio/camilla-hall' hoverColor={'#70926E'}>Camilla Hall</Link3></ListItem>
    </List>
    <List>
      <ListItem><Header4>Contact</Header4></ListItem>
      <ListItem><Link5 href='tel:(267) 304 1674' color={'#000000'} fontFamily={'Roboto'} hoverColor={'#70926E'}>Call</Link5></ListItem>
      <ListItem><Link3 to='/contact' hoverColor={'#70926E'}>Connect</Link3></ListItem>
    </List>
    <List>
      <ListItem><Header4>Legal</Header4></ListItem>
      <ListItem><Link3 to='/privacy-policy' hoverColor={'#70926E'}>Privacy Policy</Link3></ListItem>
      <ListItem><Link3 to='/terms-of-service' hoverColor={'#70926E'}>Terms of Service</Link3></ListItem>
      <ListItem><Link3 to='/photo-credit' hoverColor={'#70926E'}>Photo Credit</Link3></ListItem>
    </List>
  </Container>
)

export default FooterSitemap