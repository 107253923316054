/** @jsx jsx */
import {jsx, css} from '@emotion/core'
import styled from '@emotion/styled'

// Styles
const H1 = styled.h1`
  color: #70926E;
  font-family: 'Poiret One';
  font-size: 4.5em;
  margin-top: 0;
  margin-bottom: 45px;
`

const Header1 = ({children, color='#70926E'}) => (
  <H1 css={css`color: ${color}`}>{children}</H1>
)

export default Header1