/** @jsx jsx*/
import { css, jsx } from '@emotion/core'
import styled from '@emotion/styled'

// Styles
const P = styled.p`
  font-family: 'Roboto';
  font-size: 1.25em;
  font-weight: 300;
  position: relative;
  color: white;
`

const Copyright = () => (
  <P>
    Copyright <i><b css={css`font-weight: 700;`}>Catharine Ann Farnen, LLC</b></i> • {new Date().getFullYear()}
  </P>
)

export default Copyright