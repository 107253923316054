/** @jsx jsx */
import { css, jsx } from '@emotion/core'

const Link5 = ({children, href, color='#FFFFFF', fontFamily='Quicksand', fontSize='1.5em', hoverColor='#CCCCCC', linkid, onMouseOver}) => (
  <a
    data-link-id={linkid}
    onMouseOver={onMouseOver}
    css={css`
      color: ${color};
      font-size: ${fontSize};
      font-family: ${fontFamily};
      font-weight: 300;
      text-decoration: none;
      transition: 0.1s;
      cursor: pointer;
      &:hover {
        color: ${hoverColor}
      }
    `}
    href={href}
  >
    {children}
  </a>
)

export default Link5