import React from 'react' // eslint-disable-line no-unused-vars
/** @jsx jsx */
import {jsx, css} from '@emotion/core'
import styled from '@emotion/styled'

// Styles
const P = styled.p`
  font-family: 'Quicksand';
  font-weight: 300;
  margin-bottom: 30px;
  color: #828282;
  font-size: 1.25em;
`

const Paragraph1 = ({children, color='#828282', innerHTML}) => (
  <>
    {innerHTML && 
      <P css={css`color: ${color}`} dangerouslySetInnerHTML={innerHTML}/>
    }
    {children &&
      <P css={css`color: ${color}`}>{children}</P>
    }
  </>
)

export default Paragraph1